import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { AppConfig, Scheme } from 'app/core/config/app.config';
import { FuseConfigService } from '@fuse/services/config';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';

@Component({
    selector     : 'classic-layout',
    templateUrl  : './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    config: AppConfig;
    isProd = false;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfigService: FuseConfigService,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.isProd = environment.production;
        const scheme: Scheme = this._userService.colorScheme;
        this._fuseConfigService.config = { scheme };

        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                if (user.tabs.length) {
                    const tabs = user.tabs
                    const navTabs: FuseNavigationItem[] = []
                    for (const x of tabs) {
                        const children: FuseNavigationItem[] = []
                        for (const y of x.views) {
                            children.push({
                                id: y.name,
                                    title: y.name,
                                    type: 'basic',
                                    icon: y.icon === 'desktop_windows' ? null : `heroicons_outline:${y.icon}`,
                                    link: `${x.path}${y.path}`
                            })
                        }
                        navTabs.push({
                            id: x.alias,
                            title: x.name,
                            type: 'collapsable',
                            icon: x.icon === 'idk' ? null : `heroicons_outline:${x.icon}`,
                            children,

                        })
                    }
                    this.navigation = {
                        default: navTabs,
                        compact: navTabs,
                        futuristic: navTabs,
                        horizontal: navTabs
                    }
                }
            });

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {

                // Store the config
                this.config = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    changeScheme(selectedScheme: Scheme): void
    {
        this._userService.colorScheme = selectedScheme;
        const scheme: Scheme = this._userService.colorScheme;
        this._fuseConfigService.config = { scheme };
    }
}
