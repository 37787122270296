import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { NotFoundComponent } from './modules/auth/not-found/not-found.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {   path: '', pathMatch : 'full', redirectTo: 'tienda' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'autorizacion' },

    // Landing routes
    {
        path: 'tienda',
        component: LayoutComponent,
        data: {
            layout: 'material'
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/quickshop/quickshop.module').then(m => m.QuickshopModule) },
        ]
    },
    {
        path: 'tienda-v2',
        component: LayoutComponent,
        data: {
            layout: 'centered'
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/shop/shop.module').then(m => m.ShopModule) },
        ]
    },
    // Sign in
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
        ]
    },
    // Sign out
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
        ]
    },
    // Protected routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'reportes', loadChildren: () => import('app/modules/reports/reports.module').then(m => m.ReportsModule) },
            { path: 'biblioteca', loadChildren: () => import('app/modules/library/library.module').then(m => m.LibraryModule) },
            { path: 'autorizacion', loadChildren: () => import('app/modules/authorization/authorization.module').then(m => m.AuthorizationModule) },
            { path: 'administracion', loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule) },
            { path: 'punto-de-venta', loadChildren: () => import('app/modules/sales-point/sales-point.module').then(m => m.SalesPointModule) },
        ]
    },
    // Everything else
    {
        path: '**',
        component: NotFoundComponent,
    },
];
