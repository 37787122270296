import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { AppConfig, Scheme } from 'app/core/config/app.config';
import { FuseConfigService } from '@fuse/services/config';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';

@Component({
    selector     : 'material-layout',
    templateUrl  : './material.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MaterialLayoutComponent implements OnInit
{
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    config: AppConfig
    isProd = false;

    /**
     * Constructor
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.isProd = environment.production;
        const scheme: Scheme = this._userService.colorScheme;
        this._fuseConfigService.config = { scheme };

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                // Store the config
                this.config = config;
            });
    }

    changeScheme(selectedScheme: Scheme): void
    {
        this._userService.colorScheme = selectedScheme;
        const scheme: Scheme = this._userService.colorScheme;
        this._fuseConfigService.config = { scheme };
    }
}
