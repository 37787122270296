<!-- Button -->

<ng-container *transloco="let t; read: 'fullscreen'">
    <button
        mat-icon-button
        [matTooltip]="t('toggle')"
        (click)="toggleFullscreen()">
        <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
    </button>
</ng-container>


<!-- Default icon -->
<ng-template #defaultIconTpl>
    <mat-icon [svgIcon]="'heroicons_outline:arrows-expand'"></mat-icon>
</ng-template>
