<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-24"
                    src="assets/images/logo/logo-text-on-dark.svg"
                    alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div class="relative flex justify-center w-full overflow-hidden z-49 print:hidden"
        [ngClass]="{ 'bg-primary-700': isProd, 'bg-yellow-400': !isProd }">
        <div class="max-w-360 w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl sm:shadow-2xl overflow-hidden bg-card dark:bg-default">
            <!-- Top bar -->
            <div class="relative flex flex-auto flex-0 items-center h-10 px-4 md:px-6">
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
                    <div class="flex justify-center p-3 bg-yellow-800 rounded-2xl text-white" *ngIf="!isProd">Estás en ambiente de pruebas</div>
                    <button mat-icon-button (click)="changeScheme(config.scheme === 'dark' ? 'light' : 'dark')">
                        <mat-icon [svgIcon]="config.scheme === 'dark' ? 'heroicons_outline:moon' : 'heroicons_outline:sun'"></mat-icon>
                    </button>
                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                </div>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:px-8">
        <div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-xl sm:overflow-hidden bg-card dark:bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex justify-center w-full z-49 print:hidden">
        <div class="flex items-center max-w-360 w-full h-10 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
            <span class="font-medium text-secondary">Grupo Tecnología Educativa &copy; {{currentYear}}</span>
            <div class="grow"></div>
            <span class="font-medium text-secondary mr-5 hover:underline cursor-pointer" [routerLink]="['/tienda/terminos-y-condiciones']">Términos y condiciones</span>
            <span class="font-medium text-secondary mr-5 hover:underline cursor-pointer" [routerLink]="['/tienda/politica-de-privacidad']">Aviso de privacidad</span>
        </div>
    </div>

</div>
