export const environment = {
    production: false,
    url: 'https://qa-shop.tecsaenlinea.com',
    apiUrl: 'https://api-qa.tecsaenlinea.com/api/v1',
    apiNoPrefix: 'https://api-qa.tecsaenlinea.com',
    //apiUrlPrefix: 'http://127.0.0.1:3333/api/v1',
    //apiNoPrefix: 'http://127.0.0.1:3333',
    //apiUrl: 'http://localhost:3333'
    openPaySandboxMode: true,
    openPayId: 'mu3ja4cxvmumsnjtoagm',
    openPayPublicKey: 'pk_a94aa0baca2947deb245589b998520c0',
    openPayUrl: 'https://sandbox-dashboard.openpay.mx'
  };
  